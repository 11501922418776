import Head from "next/head";
import { useRouter } from "next/router";

export default function UrlPreview({ title, description, url }) {
  const router = useRouter();

  url ??= `https://human-in-the-loop.bytez.com${router.asPath}`;

  return (
    <Head>
      <title>{title}</title>
      <meta property="description" content={description} />
      <meta property="og:site_name" content="Bytez" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta
        property="og:image"
        content="https://bytez.com/logo-b-purple-circle.png"
      />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="site" />
      <meta name="twitter:card" content="website" />
      <meta name="twitter:site" content="@bytez" />
      <meta name="twitter:creator" content="@bytez" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Head>
  );
}

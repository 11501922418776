import { CardMedia } from "@mui/material";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export default observer(function InstructionsVideo({
  inlineImagesInstructions = false
}) {
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (ref && inlineImagesInstructions) {
      ref.currentTime = 122;
    }
  }, [inlineImagesInstructions, ref]);

  return (
    <CardMedia
      ref={setRef}
      controls
      component="video"
      src="/howto.mp4"
      sx={{
        width: "100%",
        objectFit: "contain",
        border: 1,
        borderColor: "grey.500",
        borderRadius: 2
      }}
    />
  );
});

import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";

export default function AllPapersProcessed({ open, onClose }) {
  const { query } = useRouter();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Mission accomplished! 🥳</DialogTitle>
      <DialogContent>
        <CardMedia
          component="img"
          alt="apollo"
          src="https://i.imgur.com/vgBacIh.gif"
          sx={{ mb: 2 }}
        />
        There are no more papers remaining in the queue, feel free to stop using
        the app.
      </DialogContent>
      <DialogActions>
        <Link shallow href={{ pathname: "/papers", query }}>
          <Button>View papers</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography
} from "@mui/material";

import { observer } from "mobx-react-lite";
import UrlPreview from "../../components/URLPreview";
import { useRouter } from "next/router";

import { useState } from "react";
import { getNextPaper, updatePaperRecord } from "../../services/firebase";

import InstructionsVideo from "../../components/InstructionsVideo";
import AllPapersProcessed from "../../components/Dialogs/AllPapersProcessed";
import WaitTenMinutes from "../../components/Dialogs/WaitTenMinutes";

const IndexPage = observer(function IndexPage() {
  const { push, query } = useRouter();

  const [openCongrats, setOpenCongrats] = useState(false);
  const [openWait, setOpenWait] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // createPaperRecords();

  return (
    <Box id="wow" bgcolor="colors.surface.variant" height="100vh">
      <UrlPreview
        title="Human in the loop"
        description="Help the classifier learn"
      />

      <AllPapersProcessed
        open={openCongrats}
        onClose={() => setOpenCongrats(false)}
      />
      <WaitTenMinutes open={openWait} onClose={() => setOpenWait(false)} />
      <Dialog maxWidth="lg" open={true}>
        <DialogTitle>Welcome to Bytez Human in the Loop</DialogTitle>

        <DialogContent>
          <Typography paragraph>
            Watch the video below to get started
          </Typography>
          <InstructionsVideo />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              try {
                const { paper, allPapersProcessed } = await getNextPaper();

                if (allPapersProcessed) {
                  setOpenCongrats(true);
                  return;
                } else if (paper === null && !allPapersProcessed) {
                  setOpenWait(true);
                  return;
                }

                const { publisher, paperID } = paper;
                const succeeded = await updatePaperRecord({
                  publisher,
                  paperID,
                  locked: true
                });

                if (succeeded) {
                  push({
                    pathname: `/edit/${publisher}/${paperID}`,
                    query: {
                      ...(query.admin ? { admin: query.admin } : {})
                    }
                  });
                } else {
                  setOpenSnackbar(true);
                }
              } catch (error) {
                console.error(error);
                // add transaction and re attempt getting another paper here on failure, not required for the moment due to edge caseyness
              }
            }}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={2e3}
        message="Load is high right now, please try again"
      />
    </Box>
  );
});

export default IndexPage;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

export default function WaitTenMinutes({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Please wait</DialogTitle>
      <DialogContent>
        The remaining eligible papers in the queue are currently processing,
        please wait 10 minutes and try again.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
}
